import React, { useState, useEffect } from 'react';
import { toast } from 'react-hot-toast';
import { useStateContext } from '../../contexts/ContextProvider';
import { useLocation, useNavigate } from 'react-router-dom';
import { MdOutlineCancel } from 'react-icons/md';
import generaPFX from '../../components/GeneraPfx';
import apiConfig from '../../services/ApiConfig';
import { useFirmanteMutations, useFirmaSimpleMutations, useVentaMutations } from '../../hooks';
import {Button} from '../../components';
import useEsignAPI from '../../hooks/useEsignAPI';

const CheckOutSignPage = () => {
  const { currentColor } = useStateContext();
  const { addFirmante } = useFirmanteMutations();
  const { addFirmaSimple } = useFirmaSimpleMutations();
  const { addVenta } = useVentaMutations();
  const { triggerEsignAPI } = useEsignAPI();

  const navigate = useNavigate();

  const [modalVisible, setModalVisible] = useState(false);


  const [rutCliente, setRutCliente] = useState('');
  const [nombreCliente, setNombreCliente] = useState('');
  const [apellidoPatCliente, setApellidoPatCliente] = useState('');
  const [apellidoMatCliente, setApellidoMatCliente] = useState('');
  const [iDTransaccion, setIDTransaccion] = useState('');
  const [codigoDescuento, setCodigoDescuento] = useState('');
  const [amount, setAmount] = useState(0);
  const [vigencia, setVigencia] = useState('');

  const [dateVenta, setDateVenta] = useState(getFirstDayOfMonth('Fin')); // Fecha inicial
  
  const [procesoOn, setProcesoOn] = useState(null);
  const [fileCount, setFileCount] = useState('');

  const location = useLocation();

  let isExecuted = false; // Flag para prevenir la doble ejecución

  useEffect(() => {
    
    if (!isExecuted) {
      isExecuted = true;  // Marcar como ejecutado

      let misDatos = localStorage.getItem('sellData');
      if (misDatos) {
        misDatos = JSON.parse(misDatos);
      } else {
        console.log('No hay datos en localStorage');
      }
      let codigoPago = "";
      let miPago = localStorage.getItem('pagoData');
      if (miPago) {
        miPago = JSON.parse(miPago);
        codigoPago = miPago.authorization_code;
      }

      
      setRutCliente(misDatos.rutCliente);
      setNombreCliente(misDatos.nombreCliente);
      setApellidoPatCliente(misDatos.apellidoPatCliente);
      setApellidoMatCliente(misDatos.apellidoMatCliente);
      setIDTransaccion(misDatos.transactionId);
      setCodigoDescuento(misDatos.codigoDescuento !== "" ? misDatos.codigoDescuento : codigoPago);
      setAmount(misDatos.totalPedido);
      setVigencia(misDatos.vigencia);

      handleCert();

   

    }
  }, [location,navigate]);

  function getFirstDayOfMonth(Como) {
    const today = new Date();
    const firstDay = new Date(today.getFullYear(), today.getMonth(), 1);
  
    // Función para formatear la fecha en dd/mm/yyyy
    function formatDate(date) {
      const day = String(date.getDate()).padStart(2, '0'); // Asegura dos dígitos
      const month = String(date.getMonth() + 1).padStart(2, '0'); // Mes comienza en 0, se suma 1
      const year = date.getFullYear();
      return `${day}/${month}/${year}`;
    }
  
    if (Como === 'Inicio') return formatDate(firstDay);
    if (Como === 'Fin') return formatDate(today);
    if (Como === 'Nada') return null;
  }
  

  const BarraModal = ({ processCount, onCancel }) => {
    return (
      <div className="fixed inset-0 bg-gray-500 bg-opacity-75 flex items-center justify-center">
        <div className="bg-white p-4 rounded-lg">
          <div className="grid grid-cols-1 gap-4">
            <div className="flex flex-col items-center">
              {/* Spinner */}
              <div className="animate-spin rounded-full h-12 w-12 border-t-4 border-blue-900 border-opacity-75 mb-4"></div>
  
              {/* Blinking Text */}
              <p className="text-sm text-blue-900 bg-opacity-75 p-2 rounded-lg mb-2 animate-pulse">
                GENERANDO FIRMA {processCount}
              </p>
            </div>
          </div>
          <div className="grid grid-cols-1 gap-4">
            <div className="flex flex-col">
              <Button
                color="white"
                bgColor={currentColor}
                text="Cancelar"
                size="sm"
                borderRadius="10px"
                width="full"
                handleClick={onCancel}
              />
            </div>
          </div>
        </div>
      </div>
    );
  };
  

    const closehandlePayment = async () => {
        setModalVisible(false);
        navigate('/');
    }

    const closePagePayment = async () => {
        navigate('/');
    }
    
    const handleCancelViewer = () => {
        setProcesoOn(null);
    };

    const handleCert = async () => {
        setModalVisible(true);
        setProcesoOn('GO');

        const esignSuccess = await triggerEsignAPI();
        if (!esignSuccess) {
            toast.error('Hubo un error al procesar la firma.');
            setProcesoOn(null);
            return;
        }

        setProcesoOn(null);
        toast.success('Firma Simple Descargada correctamente.');
    };
  
    const  handleDescargar = async () => {
    try {
            let locData = localStorage.getItem('signData');
            if (locData) {
                locData = JSON.parse(locData);
            } else {
                console.log('No hay datos en localStorage');
            return false;
            }
            let locDataP = localStorage.getItem('sellData');
            if (locDataP) {
                locDataP = JSON.parse(locDataP);
            } else {
                console.log('No hay datos en localStorage');
            return false;
            }

            await generaPFX( locData.firmaBase64 , locDataP.rutCliente + "-" + locData.numeroSolicitud )
        } catch (error) {
          console.error('Error al descargar Firma Simple!', error);
          toast.error('Hubo un error inesperado al descargar Firma Simple.');
      } 
    };

 
  return (
    <div className="bg-half-transparent w-full fixed nav-item top-0 right-0">
        <div className="overflow-y-scroll float-right h-screen duration-1000 ease-in-out dark:text-gray-200 transition-all dark:bg-[#484B52] bg-white w-full md:w-full p-8">
            <div className="flex justify-between items-center">
                <button
                    type="button"
                    onClick={closePagePayment}
                    style={{ color: 'rgb(153, 171, 180)', borderRadius: '50%' }}
                    className="text-2xl p-3 hover:drop-shadow-xl hover:bg-light-gray"
                >
                    <MdOutlineCancel />
                </button>
            </div>

            <div className="min-h-screen bg-gray-100">
                <div className="container mx-auto p-4 md:p-8">
                    <h1 className="text-2xl font-semibold mb-6">Finalizar Compra</h1>
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
                        <div className="md:col-span-2 bg-white p-6 rounded-lg shadow-lg">

 
                        </div>
                        <div className="bg-white p-6 rounded-lg shadow-lg">
                   </div>
                    </div>
                </div>
            </div>
        </div>

                {/* Modal de confirmación de pago */}
                {modalVisible && (
                    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
                        <div className="bg-white p-6 rounded-lg">
                            <h2 className="text-xl mb-4">Confirmación Pago</h2>
                            <div className="grid grid-cols-1 md:grid-cols-7 gap-4">
                                <div className="flex flex-col ">
                                    <label className="text-sm font-semibold mb-1">RUT</label>
                                    <input
                                        type="text"
                                        className="border border-gray-300 p-2 rounded"
                                        value={rutCliente}
                                        disabled
                                    />
                                </div>
                                <div className="flex flex-col md:col-span-2">
                                    <label className="text-sm font-semibold mb-1">Nombres</label>
                                    <input
                                        type="text"
                                        className="border border-gray-300 p-2 rounded"
                                        value={nombreCliente}
                                        disabled
                                    />
                                </div>
                                <div className="flex flex-col md:col-span-2">
                                    <label className="text-sm font-semibold mb-1">Apellido Paterno</label>
                                    <input
                                        type="text"
                                        className="border border-gray-300 p-2 rounded"
                                        value={apellidoPatCliente}
                                        disabled
                                    />
                                </div>
                                <div className="flex flex-col md:col-span-2">
                                    <label className="text-sm font-semibold mb-1">Apellido Materno</label>
                                    <input
                                        type="text"
                                        className="border border-gray-300 p-2 rounded"
                                        value={apellidoMatCliente}
                                        disabled
                                    />
                                </div>

                                <div className="flex flex-col md:col-span-3 ">
                                    <label className="text-sm font-semibold mb-1">Nro Transacción</label>
                                    <input
                                        type="text"
                                        className="border border-gray-300 p-2 rounded"
                                        value={iDTransaccion}
                                        disabled
                                    />
                                </div>
                                <div className="flex flex-col ">
                                    <label className="text-sm font-semibold mb-2">Fecha</label>
                                    <input
                                        type="text"
                                        className="border border-gray-300 p-2 rounded"
                                        value={dateVenta}
                                        disabled
                                    />
                                </div>
                                <div className="flex flex-col md:col-span-2 ">
                                    <label className="text-sm font-semibold mb-1">Pago</label>
                                    <input
                                        type="text"
                                        className="border border-gray-300 p-2 rounded"
                                        value={codigoDescuento}
                                        disabled
                                    />
                                </div>
     
              
                                <div className="flex flex-col  md:col-span-1 ">
                                    <label className="text-sm font-semibold mb-1">Monto</label>
                                    <input
                                        type="text"
                                        className="border border-gray-300 p-2 rounded"
                                        value={amount}
                                        disabled
                                    />
                                </div>
                                <div className="flex flex-col md:col-span-7">
                                    <div className="text-lg text-center text-lime-600 mb-4">
                                        <p className="mb-2">
                                        Gracias por preferirnos, su firma se descarga de forma automática, si no ocurre presione el boton Descargar.  
                                        </p>
                                    </div>
                                </div> 
                            </div>

                            <div className="flex justify-between">
                                <button
                                    className="bg-gray-500 text-white py-2 px-4 rounded"
                                    onClick={closehandlePayment}
                                >
                                    Finalizar
                                </button>
                                <button
                                    className="bg-blue-600 text-white py-2 px-4 rounded"
                                    onClick={handleDescargar}
                                >
                                    Descargar
                                </button>
                            </div>
                        </div>
                    </div>
                )}
      {procesoOn && <BarraModal processCount={fileCount} onCancel={handleCancelViewer} />}
    </div>
  );
};

export default CheckOutSignPage;
