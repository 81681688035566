import { useState } from 'react';
import { toast } from 'react-hot-toast';
import { generaToken, validaSerieRun, solicitudFirmaSimple, descargaDirectaFESPFX } from '../services/Esign';
import  apiConfig  from '../services/ApiConfig';
import generaPFX from '../components/GeneraPfx';

const useEsignAPI = () => {

  const triggerEsignAPI = async ( ) => {
    try {
        localStorage.removeItem('signData');
        let locData = localStorage.getItem('sellData');
        if (locData) {
          locData = JSON.parse(locData);
        } else {
          console.log('No hay datos en localStorage');
          return false;
        }

        const dataToken = await generaToken();
        if (!dataToken || !dataToken.token) {
            toast.error('Hubo un error inesperado. Problemas para conectar con entidad emisora. Reintente!!!');
            return false;
        }

        let dataRun = JSON.stringify({
            "rut": locData.rutCliente,
            "numeroDocumento": locData.numeroDocumentoCliente,
            "nombres": locData.nombreCliente,
            "apellidoPaterno": locData.apellidoPatCliente,
            "apellidoMaterno": locData.apellidoMatCliente,
            "email": locData.emailCliente,
            "origen": apiConfig.usuarioEsign
        });

        const esValido = await validaSerieRun(dataRun, dataToken.token);
        if (!esValido || !esValido.codigoValidacion) {
            toast.error('La serie ingresada asociada al RUT no es valida. Reintente!!!');
            return false;
        }

        let dataFS = {
            "rut": locData.rutCliente,
            "nombres": locData.nombreCliente,
            "apellidoPaterno": locData.apellidoPatCliente,
            "apellidoMaterno": locData.apellidoMatCliente,
            "nroSerieCedula": locData.numeroDocumentoCliente,
            "email": locData.emailCliente,
            "telefonoMovil": locData.telefonoCliente,
            "telefonoFijo": locData.telefonoCliente,
            "vigencia": locData.vigencia,
            "direccion": locData.direccionCliente,
            "clave": locData.claveCliente,
            "tipoCertificado": "FES",
            "origen": apiConfig.usuarioEsign,
            "estado": "",
            "idValidacion": esValido.codigoValidacion
        };

        const dataFirma = await solicitudFirmaSimple(dataFS, dataToken.token);
        if (!dataFirma || !dataFirma.pin) {
            toast.error('Problemas al generar Firma Simple - Solicitud. Reintente!!!');
            return false;
        }

        let dataDescarga = JSON.stringify({
            "numeroSolicitud": dataFirma.numeroSolicitud,
            "pin": dataFirma.pin,
            "clave": locData.claveCliente,
            "origen": apiConfig.usuarioEsign
        });

        const dataFirmaBase64 = await descargaDirectaFESPFX(dataDescarga, dataToken.token);
        if (!dataFirmaBase64) {
            toast.error('Problemas al generar Firma Simple - Descarga. Reintente!!!');
            return false;
        }

        let formData = {
            "transactionId": locData.transactionId,
            "numeroSolicitud": dataFirma.numeroSolicitud,
            "pin": dataFirma.pin,
            "codigoValidacion": esValido.codigoValidacion,
            "firmaBase64": dataFirmaBase64.certificado,
        };
        // Store form data to maintain state
        localStorage.setItem('signData', JSON.stringify(formData));

        await generaPFX(dataFirmaBase64.certificado, locData.rutCliente + "-" + dataFirma.numeroSolicitud )

        return true;
    } catch (error) {
      toast.error('Hubo un error inesperado.');
      return false;
    }
  };

  return { triggerEsignAPI };
};

export default useEsignAPI;
