const env = process.env.REACT_APP_NODE_ENV;

const apiConfig = {
  dev: {
    API_BASE: 'https://webcommerceserver-production.up.railway.app',
   //  API_BASE: 'http://localhost:5000',
  },
  prod: {
    API_BASE: 'https://server-production-a29c.up.railway.app',
  },
  testing: {
    API_BASE: 'https://server-production-a29c.up.railway.app',
  },

  env,
};

export default apiConfig;
