import { useMutation, useQueryClient } from "react-query";

import { crearProducto, updateProducto, deleteProducto, crearProductoPack, updateProductoPack, deleteProductoPack} from '../../services/Inventario';

import { toast } from 'react-hot-toast';
const queryKey = "inventario";

export const useInventarioMutations = () => {
  const queryClient = useQueryClient();
  const onError = (err = null) => {
    if (err.statusCode === 409) {
      return toast.error(`El valor ${err.duplicateValue} ya se encuentra registrado`);
    }
    toast.error('Error consulte con Soporte!');
  };

  const onSuccess = () => {
    toast.success('Datos Guardados Exitosamente!');
    queryClient.invalidateQueries(queryKey);
  };
  const addInventario = useMutation((body) => crearProducto(body), {
    onSuccess,
    onError: (err) => onError(err),
  });
  const updateInventario = useMutation(
    (body) => updateProducto( body),
    {
      onSuccess,
      onError: (err) => onError(err),
    }
  );
  const removeInventario = useMutation((id) => deleteProducto( id), {
    onSuccess,
    onError,
  });
  const addInventarioPack = useMutation((pack) => crearProductoPack(pack), {
    onSuccess,
    onError: (err) => onError(err),
  });
  const updateInventarioPack = useMutation((pack) => updateProductoPack(pack),
    {
      onSuccess,
      onError: (err) => onError(err),
    }
  );
  const removeInventarioPack = useMutation((id) => deleteProductoPack(id), {
    onSuccess,
    onError,
  });  
  return { addInventario, updateInventario, removeInventario , addInventarioPack, updateInventarioPack, removeInventarioPack};
};
export default useInventarioMutations;