import React, { useState } from 'react';
import { toast } from 'react-hot-toast';
import UserDetailsForm from '../../components/PedidosWeb/UserDetailsForm';
import OrderSummary from '../../components/PedidosWeb/OrderSummary';
import DeliveryPaymentOptions from '../../components/PedidosWeb/DeliveryPaymentOptions';
import { useStateContext } from '../../contexts/ContextProvider';
import {createTransbank } from '../../services/Transbank';
import {getCodigoDescuento, updateCodigoDescuento} from '../../services/CodigoDescuento';
import { useLocation, useNavigate } from 'react-router-dom';
import { MdOutlineCancel } from 'react-icons/md';
import rutValidator from '../../utils/validateRut';
import generaPFX from '../../components/GeneraPfx';
import apiConfig from '../../services/ApiConfig';
import {generaToken, validaSerieRun, solicitudFirmaSimple, descargaDirectaFESPFX } from '../../services/Esign';
import { useFirmanteMutations, useFirmaSimpleMutations, useVentaMutations } from '../../hooks';
import {Button} from '../../components';
import { v4 as uuidv4 } from 'uuid';
import useEsignAPI from '../../hooks/useEsignAPI';

const CheckOutPaymentPage = () => {
  const {currentColor, isClicked, setIsClicked } = useStateContext();
  const { addFirmante } = useFirmanteMutations();
  const { addFirmaSimple } = useFirmaSimpleMutations();
  const { addVenta } = useVentaMutations();
  const { triggerEsignAPI } = useEsignAPI();

  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [formaPago, setFormaPago] = useState('webpayplus');
  const [isChecked, setIsChecked] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [codigoDescuento, setCodigoDescuento] = useState('');

  const [certificadoBase64, setCertificadoBase64] = useState('');
  const [numeroSolicitud, setNumeroSolicitud] = useState('');

  const [msgCodigoDescuento, setMsgCodigoDescuento] = useState('');

  const [rutCliente, setRutCliente] = useState('');
  const [nombreCliente, setNombreCliente] = useState('');
  const [apellidoPatCliente, setApellidoPatCliente] = useState('');
  const [apellidoMatCliente, setApellidoMatCliente] = useState('');
  const [telefonoCliente, setTelefonoCliente] = useState('');
  const [emailCliente, setEmailCliente] = useState('');
  const [direccionCliente, setDireccionCliente] = useState('');
  const [numeroDocumentoCliente, setNumeroDocumentoCliente] = useState('');
  const [claveCliente, setClaveCliente] = useState('');
  const [reClaveCliente, setReClaveCliente] = useState('');  

  const [dateVenta, setDateVenta] = useState(getFirstDayOfMonth('Fin')); // Fecha inicial
  
  const [procesoOn, setProcesoOn] = useState(null);
  const [fileCount, setFileCount] = useState('');

  const location = useLocation();

  const { totalPedido, detallePedido } = location.state || {}; // Asegúrate de obtener el estado de la ubicación

  if (!detallePedido || detallePedido.length === 0) {
    return <div>No hay artículos en el carrito.</div>;
  }

  function getFirstDayOfMonth(Como) {
    const today = new Date();
    const firstDay = new Date(today.getFullYear(), today.getMonth(), 1);
    if(Como === 'Inicio') return firstDay;
    if(Como === 'Fin') return today;
    if(Como === 'Nada') return null;
  };

  const changeModal = () => {
    setFileCount(' ... '); 
    setFileCount('     '); 
    setFileCount(' ... ');
  };

  const BarraModal = ({ processCount, onCancel }) => {
    return (
      <div className="fixed inset-0 bg-gray-500 bg-opacity-75 flex items-center justify-center">
        <div className="bg-white p-4 rounded-lg">
          <div className="grid grid-cols-1 gap-4">
            <div className="flex flex-col">
              <p className="text-sm text-blue-900 bg-opacity-75 p-2 rounded-lg mb-2">
                GENERANDO FIRMA { processCount }
              </p>
            </div>
          </div>
          <div className="grid grid-cols-1 gap-4">
            <div className="flex flex-col">
              <Button
                color="white"
                bgColor={currentColor}
                text="Cancelar"
                size="sm"
                borderRadius="10px"
                width="full"
                handleClick={onCancel}
              />
            </div>
          </div>
        </div>
      </div>
    );
  };

  const validateForm = async () => {

        if (!rutValidator(rutCliente)) {
            toast.error("RUT Ingresado no es Válido!!!");
            return false;
        }

        if (!nombreCliente.trim()) {
            toast.error("El nombre es obligatorio.");
            return false;
        }

        if (!apellidoPatCliente.trim()) {
            toast.error("El apellido paterno es obligatorio.");
            return false;
        }

        if (!apellidoMatCliente.trim()) {
            toast.error("El apellido materno es obligatorio.");
            return false;
        }

        // Validar que el teléfono sea numérico y tenga exactamente 12 dígitos
        const telefonoRegex = /^\d{11}$/;
        if (!telefonoRegex.test(telefonoCliente)) {
            toast.error("El teléfono debe ser un número de 12 dígitos.");
            return false;
        }

        if (!emailCliente.includes("@")) {
            toast.error("El email no es válido.");
            return false;
        }

        // Validar que el número de documento sea numérico y tenga exactamente 9 dígitos
        const numeroDocumentoRegex = /^\d{9}$/;
        if (!numeroDocumentoRegex.test(numeroDocumentoCliente)) {
            toast.error("El número de documento debe ser un número de 9 dígitos, que se encuenra en su Cédula de Identidad.");
            return false;
        }

        // Validar que el número de documento sea numérico y tenga exactamente 9 dígitos
        const claveClienteRegex = /^\d{4}$/;
        if (!claveClienteRegex.test(claveCliente)) {
            toast.error("La clave debe ser un número de 4 dígitos.");
            return false;
        }

        if (claveCliente !== reClaveCliente && !claveCliente.trim() ) {
            toast.error("Las contraseñas no coinciden, y son obligatorias.");
            return false;
        }

        // Verificar si los términos y condiciones han sido aceptados
        if (!isChecked) {
            toast.error("Debes aceptar los términos y condiciones.");
            return false;
        }

        if (codigoDescuento) {
                const miDescuento = await getCodigoDescuento(codigoDescuento);
                console.log('RESULTADO MI DESCUENTO', miDescuento);

                if (!miDescuento || miDescuento === null) {
                    setCodigoDescuento('');
                    toast.error("El código de descuento no es válido.");
                    console.log('MANDE MENSAJE Y SALGO RETURN FALSE');
                    return false;
                } else {
        
                    if (miDescuento.estado === "DISPONIBLE") {
                        setMsgCodigoDescuento("Descuento Aplicado");
                    } else {
                        setCodigoDescuento('');
                        toast.error("El código de descuento no es válido.");
                        return false;
                    }
                }
        }
        return true;
    };

    const closehandlePayment = async () => {
        setMsgCodigoDescuento("");
        setModalVisible(false);
        navigate('/');
    }

    const closePagePayment = async () => {
        navigate('/', { state: { cart: detallePedido }});
    }
    
    const handleCancelViewer = () => {
        setProcesoOn(null);
    };

    const handleSubmit = async () => {  
        try {  
            console.log("ANTES DE VALIDATE FORM");
        
            const isValid = await validateForm();
            if (!isValid) {
                console.log("VALIDATE FORM ES FALSE");
                return false;
            } else {
                // Store form data to maintain state
                localStorage.removeItem('sellData');
                let transactionId = uuidv4();

                let tmpVigencia = detallePedido.map( item => {
                    let vigencia;
                    // Evaluar según el valor de item.id
                    if (item.id === "66b29a26a8a99d47ee8179f9") {
                      vigencia = "365";
                    } else if (item.id === "66b2d338a8a99d47ee8179fa") {
                      vigencia = "730";
                    } else if (item.id === "66d9065fc05820de0115a044") {
                      vigencia = "1095";
                    }
                    return vigencia;
                });

                let detalleVenta = detallePedido.map((item, index) => ({
                    lin: index + 1,  // Línea, puedes usar el índice del array
                    producto: item.id,  // Asignar el id como producto
                    cantidad: item.quantity,  // Asignar la cantidad
                    precioUnitario: item.price,  // Asignar el precio unitario
                    precioTotal: item.price * item.quantity  // Calcular el precio total
                }));
        
                let formData = {
                    "rutCliente": rutCliente,
                    "nombreCliente": nombreCliente, 
                    "apellidoPatCliente": apellidoPatCliente, 
                    "apellidoMatCliente": apellidoMatCliente, 
                    "telefonoCliente": telefonoCliente, 
                    "emailCliente": emailCliente, 
                    "direccionCliente": direccionCliente, 
                    "numeroDocumentoCliente": numeroDocumentoCliente, 
                    "claveCliente": claveCliente, 
                    "codigoDescuento": codigoDescuento, 
                    "totalPedido": totalPedido, 
                    "detalleVenta": detalleVenta,
                    "transactionId": transactionId,
                    "vigencia": tmpVigencia[0]
                };
                // Store form data to maintain state
                localStorage.setItem('sellData', JSON.stringify(formData));
            
                if (codigoDescuento){
                    // Limpia la URL después de procesar el token
                    navigate('/checkout-sign', { replace: true });
                 //   handleCert();
                }
                else
                {
                    handlePayment(); 
                }
            }

      } catch (error) {
        toast.error('Hubo un error inesperado. [1001]');
      }

    };

    const handleCert = async () => {
        setModalVisible(true);
        setProcesoOn('GO');

        const esignSuccess = await triggerEsignAPI();
        if (!esignSuccess) {
            toast.error('Hubo un error al procesar la firma.');
            setProcesoOn(null);
            return;
        }

/*
        // CREAR FIRMANTE
        let firmanteData = {
            "rut": rutCliente,
            "nombres": nombreCliente,
            "apellidoPaterno": apellidoPatCliente,
            "apellidoMaterno": apellidoMatCliente,
            "email": emailCliente,
            "telefonoMovil": telefonoCliente,
            "telefonoFijo": telefonoCliente,
            "direccion": direccionCliente
        };

        let firmanteDB = await addFirmante.mutateAsync( firmanteData )

        if (!firmanteDB) {
            toast.error('Problemas al registrar los datos del firmante.');
            setProcesoOn(null);
            return;
        }

        await updateCodigoDescuento(codigoDescuento);
*/
        setProcesoOn(null);
        toast.success('Firma Simple Descargada correctamente.');
    };
  
    const  handleDescargar = async () => {
    try {
            let locData = localStorage.getItem('signData');
            if (locData) {
                locData = JSON.parse(locData);
            } else {
                console.log('No hay datos en localStorage');
            return false;
            }
            let locDataP = localStorage.getItem('sellData');
            if (locDataP) {
                locDataP = JSON.parse(locDataP);
            } else {
                console.log('No hay datos en localStorage');
            return false;
            }

            await generaPFX( locData.firmaBase64 , locDataP.rutCliente + "-" + locData.numeroSolicitud )
        } catch (error) {
          console.error('Error al descargar Firma Simple!', error);
          toast.error('Hubo un error inesperado al descargar Firma Simple.');
      } 
    };

  const handlePayment = async () => {
    setModalVisible(false);
    try {
        setIsLoading(true);

        let dataToCreate = {
          "amount":totalPedido, 
          "sessionId": `S-${Math.floor(Math.random() * 10000)}`, 
          "buyOrder": `O-${Math.floor(Math.random() * 10000)}`, 
          "returnUrl": apiConfig.appUrl +  "/checkout-result" // http://localhost:3000
        };

        const {token,url} = await createTransbank(dataToCreate);

          // Redirige al usuario a la página de pago de Transbank
        window.location.href = `${url}?token_ws=${token}`;

      // You would handle success or failure later in a result page
    } catch (error) {
      console.error('Error during payment', error);
      // Handle error (e.g., show modal or error message)
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="bg-half-transparent w-full fixed nav-item top-0 right-0">
        <div className="overflow-y-scroll float-right h-screen duration-1000 ease-in-out dark:text-gray-200 transition-all dark:bg-[#484B52] bg-white w-full md:w-full p-8">
            <div className="flex justify-between items-center">
                <button
                    type="button"
                    onClick={closePagePayment}
                    style={{ color: 'rgb(153, 171, 180)', borderRadius: '50%' }}
                    className="text-2xl p-3 hover:drop-shadow-xl hover:bg-light-gray"
                >
                    <MdOutlineCancel />
                </button>
            </div>

            <div className="min-h-screen bg-gray-100">
                <div className="container mx-auto p-4 md:p-8">
                    <h1 className="text-2xl font-semibold mb-6">Finalizar Compra</h1>
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
                        <div className="md:col-span-2 bg-white p-6 rounded-lg shadow-lg">
                            <UserDetailsForm
                                rutCliente={rutCliente}
                                setRutCliente={setRutCliente}
                                nombreCliente={nombreCliente}
                                setNombreCliente={setNombreCliente}
                                apellidoPatCliente={apellidoPatCliente}
                                setApellidoPatCliente={setApellidoPatCliente}
                                apellidoMatCliente={apellidoMatCliente}
                                setApellidoMatCliente={setApellidoMatCliente}
                                direccionCliente={direccionCliente}
                                setDireccionCliente={setDireccionCliente}
                                telefonoCliente={telefonoCliente}
                                setTelefonoCliente={setTelefonoCliente}
                                emailCliente={emailCliente}
                                setEmailCliente={setEmailCliente}
                                numeroDocumentoCliente={numeroDocumentoCliente}
                                setNumeroDocumentoCliente={setNumeroDocumentoCliente}
                                claveCliente={claveCliente}
                                setClaveCliente={setClaveCliente}
                                reClaveCliente={reClaveCliente}
                                setReClaveCliente={setReClaveCliente}
                            />
                            <DeliveryPaymentOptions
                                formaPago={formaPago}
                                setFormaPago={setFormaPago}
                                isChecked={isChecked}
                                setIsChecked={setIsChecked}
                                codigoDescuento={codigoDescuento}
                                setCodigoDescuento={setCodigoDescuento}
                            />
                        </div>
                        <div className="bg-white p-6 rounded-lg shadow-lg">
                            <OrderSummary totalPedido={totalPedido} detallePedido={detallePedido} />
                            <button
                                onClick={handleSubmit}
                                className="bg-blue-600 text-white py-2 px-4 rounded mt-4"
                            >
                            Finalizar Pedido
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

                {/* Modal de confirmación de pago */}
                {modalVisible && (
                    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
                        <div className="bg-white p-6 rounded-lg">
                            <h2 className="text-xl mb-4">Confirmar Pago</h2>
                            <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
                                <div className="flex flex-col ">
                                    <label className="text-sm font-semibold mb-1">RUT</label>
                                    <input
                                        type="text"
                                        className="border border-gray-300 p-2 rounded"
                                        value={rutCliente}
                                        disabled
                                    />
                                </div>
                                <div className="flex flex-col md:col-span-3">
                                    <label className="text-sm font-semibold mb-1">Nombres</label>
                                    <input
                                        type="text"
                                        className="border border-gray-300 p-2 rounded"
                                        value={nombreCliente}
                                        disabled
                                    />
                                </div>
                                <div className="flex flex-col md:col-span-2">
                                    <label className="text-sm font-semibold mb-1">Apellido Paterno</label>
                                    <input
                                        type="text"
                                        className="border border-gray-300 p-2 rounded"
                                        value={apellidoPatCliente}
                                        disabled
                                    />
                                </div>
                                <div className="flex flex-col md:col-span-2">
                                    <label className="text-sm font-semibold mb-1">Apellido Materno</label>
                                    <input
                                        type="text"
                                        className="border border-gray-300 p-2 rounded"
                                        value={apellidoMatCliente}
                                        disabled
                                    />
                                </div>
                                <div className="flex flex-col md:col-span-2 ">
                                    <label className="text-sm font-semibold mb-1">Código Descuento</label>
                                    <input
                                        type="text"
                                        className="border border-gray-300 p-2 rounded"
                                        value={codigoDescuento}
                                        disabled
                                    />
                                </div>
                                <div className="flex flex-col ">
 
                                    <label className="text-sm font-semibold mb-1">...</label>
                                    <input
                                        type="text"
                                        className="noborder border-gray-300 p-2 rounded"
                                        value={msgCodigoDescuento !== '' ? msgCodigoDescuento : '' }
                                        disabled
                                    />

                                </div>
                                <div className="flex flex-col md:col-span-3">
                                    <div className="text-lg text-center text-lime-600 mb-4">
                                        <p className="mb-2">
                                        Gracias por Preferirnos, la descarga de su firma es automática.  
                                        </p>
                                    </div>
                                </div> 
                            </div>

                            <div className="flex justify-between">
                                <button
                                    className="bg-gray-500 text-white py-2 px-4 rounded"
                                    onClick={closehandlePayment}
                                >
                                    Finalizar
                                </button>
                                <button
                                    className="bg-blue-600 text-white py-2 px-4 rounded"
                                    onClick={handleDescargar}
                                >
                                    Descargar
                                </button>
                            </div>
                        </div>
                    </div>
                )}
      {procesoOn && <BarraModal processCount={fileCount} onCancel={handleCancelViewer} />}
    </div>
  );
};

export default CheckOutPaymentPage;
